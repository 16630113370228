<template>
    <div class="w-full">
        <div class="flex items-center text-center space-x-4 justify-evenly" id="otp-input">
            <input ref="otp-input" v-for="(digit, index) in digits" :key="digit + index" v-model="inputValues[index]" class="h-12 w-12 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg text-center justify-center sm:text-sm focus:ring-blue-600 focus:border-blue-600" type="number" placeholder="_" @input="onInput(index)" @keydown="keydownHandler(index, $event)" />
        </div>
    </div>
</template>

<script>
export default {
    name: "otp-input",
    props: {
        digits: {
            type: Number,
            default: 6,
        },
    },
    data() {
        return {
            inputValues: [],
            joinedValue: "",
        };
    },
    methods: {
        keydownHandler(index, e) {
            if (e.keyCode === 8 && e.target.value === "") {
                this.$refs["otp-input"][Math.max(0, index - 1)].focus();
            }
        },
        onInput(index) {
            const [first, ...rest] = this.inputValues[index];
            this.inputValues[index] = first || first === 0 ? first : "";
            const lastInput = index === this.digits - 1;
            const insertedVal = first !== undefined;
            if (insertedVal && !lastInput) {
                this.$refs["otp-input"][index + 1].focus();
                this.$refs["otp-input"][index + 1].value = rest.join("");
                this.$refs["otp-input"][index + 1].dispatchEvent(new Event("input"));
            }
            this.joinedValue = this.inputValues.map((value) => value).join("");
            this.$emit("on-change", this.joinedValue);
            if (this.joinedValue.length === this.digits) {
                this.onComplete(this.joinedValue);
            }
        },
        onComplete(value) {
            this.$emit("on-complete", value);
        },
    },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
